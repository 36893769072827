import { ThemeProvider } from "@emotion/react";
import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import ThemeService from "./app/services/ThemeService";
import './theme/Styles.scss';

const IFrameShow = React.lazy(() => import("./app/components/i-frame-window/IFrameShow"));
const IFrame = React.lazy(() => import("./app/components/i-frame-window/IFrame"));
const Office = React.lazy(() => import("./app/components/Office"));
const IFrameDoc = React.lazy(() => import("./app/components/i-frame-window/IFrameDoc"));
const ApiOfficeLoader = React.lazy(() => import("./app/components/common/ApiOfficeLoader"));
const ApiPublicLoader = React.lazy(() => import("./app/components/common/ApiPublicLoader"));
const HeToastify = React.lazy(() => import("./app/components/common/HeToastify"));

const App = () => {
  return (
    <ThemeProvider theme={ThemeService.theme}>
      <Routes>
        <Route path="/" element={<LayoutOffice />}>
          <Route index element={<Home />} />
          <Route path="show/:code" element={<React.Suspense fallback={<>...</>}><IFrameShow /></React.Suspense>} />
          <Route path="iframe/doc" element={<React.Suspense fallback={<>...</>}><IFrameDoc /></React.Suspense>} />
          <Route path="office/*" element={<React.Suspense fallback={<>...</>}><Office /></React.Suspense>} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="iframe" element={<><ApiPublicLoader /><IFrame /></>} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

function LayoutOffice() {
  return (<>
    <ApiOfficeLoader />
    <HeToastify />
    <Outlet />
  </>
  );
}

function Home() {
  return (
    <div>
      <h2>Website under construction...</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
