import { createTheme, PaletteOptions } from '@mui/material/styles';

const ppalette: any = {
    primary: {
        main: '#1D70B8',
        light: '#018ACA',
        dark: '#002E5F'
    },
    secondary: {
        main: '#605E5C',
        light: '#BBC3C2',
        dark: '#323130'
    },
    text: {

    },
} as PaletteOptions

const theme = createTheme({
    palette: ppalette,
    typography: {
        fontFamily: [
            'Roboto', 'sans-serif'
        ].join(','),
    },
    shape: {
        borderRadius: 0
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontsize: '12px',
                    fontWeight: '600'
                },
                startIcon: {
                    marginRight: '4px'
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    //width: '100%'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    //padding: '5px 8px'
                },
                notchedOutline: {
                    //borderColor: 'rgba(0, 0, 0, 0.4)'
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: ppalette.secondary.light
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    //marginLeft: '8px'
                }
            }
        }
    }
});

const ThemeService = {
    theme: theme,
};

export default ThemeService;